.InvoiceSupplierPage {
  .ResetInvoiceFilters {
    color: #999;
  }

  .colFileNumber {
    width: 100px;
    white-space: nowrap;
  }

  .colSupplier {
    width: 100px;

    .badge {
      cursor: pointer;
    }
  }

  .colTitle {

  }

  .colStatus {
    width: 100px;
  }

  .colRequestNumber {
    width: 150px;
  }

  .colNote {
      min-width: 150px;
  }

  .colCreated,
  .colFinishAt {
    width: 170px;
  }

  .colActions {

  }
}

.SupplierValidationDetailContainer {
  .SupplierValidationDetailPage {
    .rowInfoInvoice {
      margin: 0;
      padding-top: 8px;

      label {
        font-weight: 500;
        color: #212529;
        width: 140px;
      }

      &:nth-of-type(odd) {
        background-color: #f9fafb;
      }
    }

    .StatusFilter {
      border-radius: 0.25rem;
    }
  }

  .colOrderNumber {
    width: 100px;
  }

  .colCreated,
  .colCheckingNumber {
    width: 200px;
  }

  .colStatus {
    width: 100px;
  }

  .colMessage {
    .tooltip-handler {
      color: #FF0000;
      cursor: pointer;
    }
  }
}
