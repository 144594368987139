.FilterInvoices {
  .InputWrapper {
    .iconSearch {
      left: 15px;
      top: 12px;
      color: #8a8a8a;
    }
    .iconClose {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.2);
      right: 10px;
      top: 12px;
      z-index: 1;
    }
    input {
      width: 260px;
      @media (max-width: 767px) {
        width: 190px;
      }
      padding-right: 30px;
      padding-left: 35px;
      border-radius: 0;
      margin-right: -1px;
      &:hover, &:focus {
        border-color: hsl(0,0%,80%)!important;
        box-shadow: none;
      }
    }
    &:first-child {
      input {
        border-radius: 4px;
      }
    }
  }
  .statusFilter {
    //border-radius: 0 4px 4px 0;
  }
  select {
    width: 130px;
    border-color: hsl(0,0%,80%)!important;
    box-shadow: none;
    &:hover, &:focus {
      border-color: hsl(0,0%,80%)!important;
      box-shadow: none;
    }

    &.shippingCarrierFilter,
    &.approvalStatusFilter{
      width: 190px;
    }
  }
}

.InvoicePage {
  td {
    word-break: break-word;
  }
  .colOrderNumber {
    width: 125px;
  }
  .colActions {
    width: 155px;
    i {
      cursor: pointer;
    }
  }
  .colCheckingNumber {
    width: 270px;
  }
  .colStatus {
    width: 100px;
  }
  .colCreated, .colFinishAt {
    width: 250px;
    @media (min-width: 1025px) and (max-width: 1400px) {
      width: 125px;
    }
  }
  .colRequestNumber {
    width: 200px;
    @media (min-width: 1025px) and (max-width: 1400px) {
      width: 150px;
    }
  }
}
.wrapTable {
  @media (max-width: 1024px) {
    overflow-x: scroll;
    table {
      width: max-content;
    }
  }
}
.InvoiceContainer {
  .rowInfoInvoice {
    margin: 0;
    padding-top: 8px;
    label {
      font-weight: 500;
      color: #212529;
      width: 140px;
    }
    &:nth-of-type(odd) {
      background-color: #f9fafb;
    }
  }
}
.ActionModal {
  .ModalButtons {
    button {
      text-transform: capitalize;
    }
  }
}