body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.wrapContainer {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.fas, .fa {
  font-family: FontAwesome;
  font-style: normal;
}

.SelectWrap {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    padding-right: 25px;
    position: relative;
    background-color: transparent!important;
    z-index: 1;
  }
  i {
    right: 10px;
    bottom: 11px;
    font-size: 16px;
    color: #8a8a8a;
  }
}