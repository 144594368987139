.CreateInvoiceModal {
  label {
    font-weight: 500;
  }
}

.InvoiceRecordsModal {
  max-width: 1200px !important;
  width: 100%;
}

.RecordsPopup {
  > span {
    cursor: pointer;
  }
}

.modal-footer {
  display: block;
}

.ModalButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
