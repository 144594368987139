.FilterRecords {
  @media (min-width: 1024px) and (max-width: 1400px) {
    flex-direction: column;
    .FilterStatus {
      margin-bottom: 15px!important;
    }
  }
  label {
    font-weight: bold;
    margin-bottom: 0;
  }
  .InputWrapper {
    .iconSearch {
      left: 15px;
      top: 12px;
      color: #8a8a8a;
    }
    .iconClose {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.2);
      right: 10px;
      top: 12px;
      z-index: 1;
    }
    input {
      width: 255px;
      @media (min-width: 1025px) and (max-width: 1400px) {
        width: 220px;
      }
      padding-right: 30px;
      padding-left: 35px;
      border-radius: 0;
      margin-right: -1px;
      &:hover, &:focus {
        border-color: hsl(0,0%,80%)!important;
        box-shadow: none;
      }
    }
    &:first-child {
      input {
        border-radius: 4px 0 0 4px;
      }
    }
  }
  .StatusFilter {
    border-radius: 0;
    border-color: hsl(0,0%,80%)!important;
    box-shadow: none;
    &:hover, &:focus {
      border-color: hsl(0,0%,80%)!important;
      box-shadow: none;
    }
    &.Force {
      margin-left: -1px;
      border-radius: 0 4px 4px 0;
    }
  }
  .ExportRecords {
    button {
      width: 170px;
    }
  }
}

.ResultInvoices {
  table {
    .colStatus {
      width: 90px;
      i {
        cursor: pointer;
      }
    }
    .colCreated {
      width: 185px;
    }
    .colOrderNumber {
      width: 140px;
    }
    .colPaymentStatus {
      width: 150px;
    }
    .colCheckingNumber {
      width: 255px;
    }
  }
}
.rowInfoInvoice {
  word-break: break-word;
}