$pending: #81d4fa;
$printing: #00bcd4;
$printed: #009688;
$transferring: #4caf50;
$transferred: #4caf50;
$confirmed: #cddc39;
$sewing: #aeea00;
$done: #64dd17;
$cancel-ship: #ffa000;
$moved: #fdd835;

.ExportErrorShippingPage {
  .ExportErrorShippingForm {
    .Input {
      .File {
        width: 600px;
        line-height: 40px;
        border-radius: 4px 0 0 4px;
        padding: 0 15px;
        height: 40px;
        border: 1px solid lightgray
      }

      button {
        border-radius: 0 4px 4px 0;
        padding: 0 15px;
        line-height: 40px;
      }
    }

    select {
      border: 1px solid #DDD;
      background: transparent;
      padding: 9px 5px;
      border-left: none;
      border-right: none;
      outline: none;
    }
  }

  .ShippingErrorList{
    .Action{
      text-align: right;
      width: 200px;
    }
    .StatusFile{
      width: 70px;
    }
  }
}

.ant-message {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-message-rtl {
  direction: rtl;
}

.ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}

.FileErrorListPage {
  .TableData {

    .table {
      border-bottom: 1px solid #eee;
    }

    .TableHead {
      tr {
        background: #F3F5F4;

        th {
          border-bottom: 1px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #2F3230;
        }
      }
    }

    .TableRow {
      height: 65px;

      td {
        vertical-align: middle !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        &.DownloadInvoice {
          button {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .StatusFile {
        .status {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          padding: 2px 6px;
          border-radius: 10rem;

          &.created {
            background-color: #03a9f4;
            color: #fff;
          }

          &.processing {
            background-color: #00acc1;
            color: #fff;
          }

          &.done {
            background-color: #00c853;
            color: #fff;
          }

          &.failed {
            background-color: #ffa000;
            color: #fff;
          }

          &.cancelled {
            background-color: #721c24;
            color: #fff;
          }
        }
      }
    }
  }
}