.FilterLabels {
    @media (min-width: 1024px) and (max-width: 1400px) {
        .col-2 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
    }

    .InputWrapper {
        .iconSearch {
            left: 15px;
            bottom: 12px;
            color: #8a8a8a;
            font-size: 14px;
        }

        .iconClose {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.2);
            right: 10px;
            top: 12px;
            z-index: 1;
        }

        input {
            padding-right: 30px;
            padding-left: 35px;
            margin-right: -1px;

            &:hover, &:focus {
                border-color: hsl(0, 0%, 80%) !important;
                box-shadow: none;
            }
        }
    }

    select {
        border-color: hsl(0, 0%, 80%) !important;
        box-shadow: none;

        &:hover, &:focus {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none;
        }
    }
}

.LabelsPage {
    .colOrderNumber {
        width: 120px;
    }

    .colSource {
        width: 100px;
    }

    .colShippingCarrier {
        width: 155px;
    }

    .colShippingWeight {
        width: 100px;
    }

    .colShippingCountry {
        width: 100px;
    }

    .colActions {
        width: 140px;

        i {
            cursor: pointer;
        }
    }

    .colCheckingNumber {
        width: 270px;
    }

    .colStatus {
        width: 90px;
    }

    .colCreated, .colFinishAt {
        width: 160px;
        @media (min-width: 1025px) and (max-width: 1400px) {
            width: 115px;
        }
    }

    .colRequestNumber {
        width: 200px;
        @media (min-width: 1025px) and (max-width: 1400px) {
            width: 150px;
        }
    }
}

.wrapTable {
    @media (max-width: 1400px) {
        overflow-x: scroll;
        table {
            width: max-content;
        }
    }
}

.LabelsContainer {
    .rowInfoInvoice {
        margin: 0;
        padding-top: 8px;

        label {
            font-weight: 500;
            color: #212529;
            width: 140px;
        }

        &:nth-of-type(odd) {
            background-color: #f9fafb;
        }
    }
}

.ActionModal {
    .ModalButtons {
        button {
            text-transform: capitalize;
        }
    }
}

.ModalCustom {
    max-width: 950px !important;
}