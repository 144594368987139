.TableLoading {
    td {
        vertical-align: middle !important;
        height: 60px;

        .Cell {
            width: 100%;
            height: 10px;
        }

        .ImageCell {
            height: 60px;
        }
    }
}
