.ant-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 15px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-layout .trigger:hover {
  color: #1890ff;
}

.ant-layout .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout {
  height: 100%;
  overflow-y: scroll;
}

.site-layout {
  width: calc(100% - 225px);
}

.site-layout .site-layout-background {
  background: #fff;
  margin-bottom: 30px;
}

.navLeft {
  width: 225px;
}

.navLeft ul {
  padding: 0;
  margin-top: 15px;
  margin-right: -15px;
}

.navLeft ul li {
  list-style: none;
  width: 100%;
  display: flex;
  padding: 0 20px;
  align-items: center;
}

.navLeft ul li a {
  width: 100%;
  display: block;
  padding: 12px 15px;
  color: hsla(0, 0%, 100%, .65);
}

.navLeft ul li a:hover {
  color: #ffffff;
}

.navLeft ul li.active {
  background-color: #1890ff;
}

.navLeft ul li.active a {
  color: #ffffff;
}

#root {
  height: 100%;
  overflow-x: hidden;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider {
  .ant-menu {
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;
    }
  }
}

.ant-layout-header {
  padding-right: 25px;

  .ant-menu-item {
    display: flex !important;
    align-items: center;
  }
}

.PageTitle {
  font-size: 1.75rem;
  font-weight: 500;
}

.siteHeader {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
