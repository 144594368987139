.LoginPage {
    .LoginInner {
        max-width: 500px;
        margin: 0 auto;

        h1 {
            font-size: 2.5rem;
            margin-top: 0;
            margin-bottom: .5em;
            color: rgba(0, 0, 0, .85);
            font-weight: 500;
            line-height: 1.2;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
    }
}